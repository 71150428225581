import React from 'react'
import ImageWebp from '../../../../components/ImageWebp'
import useWidth from '../../../../hooks/window/useWidth'
import { WIDTH_LG } from '../../../../utils/breakpoints'
import { Section } from './style'

const EtapasDoPrograma = () => {
  const windowWidth = useWidth(100)

  return (
    <Section id='etapas' className='bg-orange--extra py-5'>
      <div className='container'>
        <div className='col-12'>
          <h2 className='fs-24 lh-30 fs-lg-32 lh-lg-40 fs-xl-40 lh-xl-50 fw-600 text-white text-center mb-4 py-2'>
            Etapas do programa
          </h2>
        </div>
        {windowWidth >= WIDTH_LG ? (
          <><div className='col-12 mt-5 d-lg-flex justify-content-lg-center'>
            <ImageWebp
              arrayNumbers={[0, 0, 912, 1033]}
              arrayNumbersHeight={[0, 0, 956, 1082]}
              pathSrc='https://central-imagens.bancointer.com.br/images-without-small-versions/go-tech-1440/image.webp'
              altDescription=''
            />
          </div>
          <a title='"...inquieto, tem os olhos sempre no futuro em busca do novo." Parabéns! Essa é uma dica para você ganhar mais pontos na dinâmica em grupo, fale essa frase no momento em que estiver se apresentando na DG.'>*</a></>
        ) : (
          <><div className='col-12 d-md-flex justify-content-md-center'>
            <ImageWebp
              arrayNumbers={[304, 304, 0, 0]}
              arrayNumbersHeight={[1551, 1551, 0, 0]}
              pathSrc='https://central-imagens.bancointer.com.br/images-without-small-versions/go-tech-mobile/image.webp'
              altDescription=''
            />
          </div>
          <a alt='"...inquieto, tem os olhos sempre no futuro em busca do novo." Parabéns! Essa é uma dica para você ganhar mais pontos na dinâmica em grupo, fale essa frase no momento em que estiver se apresentando na DG.'>*</a></>
        )}
      </div>
    </Section>
  )
}

export default EtapasDoPrograma
