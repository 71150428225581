import React from 'react'

import { Section } from './style'
import faqData from '../../pageContext.json'
import { grayscale } from '../../../../styles/colors'

// Components
import Faq from '../../../../components/Faq'

const FAQ = () => {
  return (
    <Section id='perguntas' className='py-5'>
      <div className='container'>
        <div className='row'>
          <div className='col-12'>
            <h2 className='fs-32 lh-40 fs-xl-40 lh-xl-50 mb-5 text-grayscale--500 fw-600'>Perguntas frequentes</h2>
          </div>
          <Faq
            columns={{ sm: 1, md: 1, lg: 1, xl: 1 }}
            answerData={faqData.structuredData.faq}
            className='text-grayscale--500'
            questionColor='text-grayscale--500'
            answersColor={grayscale[500]}
          />
        </div>
      </div>
    </Section>
  )
}

export default FAQ
