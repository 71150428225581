import React from 'react'
import useDataLayer from '../../../../hooks/useDataLayer/dataLayerBody'
import ImageWebp from '../../../../components/ImageWebp'
import useWidth from '../../../../hooks/window/useWidth'
import { WIDTH_MD } from '../../../../utils/breakpoints'

import { ContentHeader } from './style'

const Header = () => {
  const windowWidth = useWidth(100)
  const [ sendDatalayerEvent ] = useDataLayer()

  return (
    <ContentHeader id='sobre' className='d-flex position-relative justify-content-center py-5'>
      <div className='container'>
        <div className='row justify-content-center align-items-center'>
          {windowWidth >= WIDTH_MD && (
            <div className='col-md-6 col-lg-5 offset-lg-1 order-md-last'>
              <ImageWebp
                arrayNumbers={[ 0, 332, 374, 456 ]}
                arrayNumbersHeight={[ 0, 389, 437, 533 ]}
                pathSrc='https://central-imagens.bancointer.com.br/images-without-small-versions/go-tech-hero/image.png'
                altDescription='Jovem'
              />
            </div>
          )}
          <div className='col-12 col-md-6 col-xl-5'>
            <div className='mb-4'>
              <ImageWebp
                arrayNumbers={[ 312, 332, 457, 453 ]}
                arrayNumbersHeight={[ 138, 147, 203, 201 ]}
                pathSrc='https://central-imagens.bancointer.com.br/images-without-small-versions/logo-go-tech/image.webp'
                altDescription='Logo'
              />
            </div>

            <h1 className='fs-20 lh-25 fs-lg-24 lh-lg-30 mb-3 fw-600 text-white'>
              Leve sua carreira para o próximo nível em 2024.
              <span className='d-block mt-3'>Inscrições até 08 de Janeiro</span>
            </h1>

            <div className='mt-4 py-2'>
              <a
                href='https://matchbox.digital/estagiointergotech2024-inscricao'
                target='blank'
                className='btn btn--lg btn-white text-orange--extra rounded-8 fs-14 fw-600 text-none mb-0 mw-100'
                onClick={() => {
                  sendDatalayerEvent({
                    section: 'dobra_01',
                    section_name: 'Leve sua carreira para o próximo nível em 2024. Inscrições até 08 de Janeiro',
                    element_action: 'click button',
                    element_name: 'Quero me cadastrar',
                    redirect_url: 'https://matchbox.digital/estagiointergotech2024-inscricao',
                  })
                }}
              >
                Quero me cadastrar
              </a>
            </div>
          </div>
        </div>
      </div>
    </ContentHeader>
  )
}

export default Header
